<template>
    <button
        class="base-button"
        :disabled="disabled"
        :class="{
            'base-button--small': size === 'small',
        }"
        v-on="$listeners"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'default',
        },
    },
}
</script>

<style lang="scss" scoped>
.base-button {
    padding: 20px;
    background-color: $color-primary;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    color: $color-primary-contrast;
    transition: all 0.1s;
    outline: none;
    cursor: pointer;

    &:focus,
    &:hover {
        background-color: lighten($color-primary, 5%);
    }

    &:active {
        background-color: darken($color-primary, 1.25%);
    }

    &:disabled {
        background-color: $color-light;
        color: rgba(0, 0, 0, 0.2);
        cursor: default;
    }

    &--small {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 0;
    }
}
</style>
